import axios from "axios";

//const server = "51.75.195.167"
const server = "api.eveonline.es"
const API_URL = "https://" + server + "/api/auth";

const signup = (username, password) => {
  return axios
    .post(API_URL + "/signup", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const signin = (username, password) => {
  return axios
    .post(API_URL + "/signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
        console.log(response.data)
      }else{
        console.log(response.data.message);
        localStorage.setItem("loginMessage", JSON.stringify(response.message));

        throw new Error("Unauthorized error: Bad credentials");
      }
      return response.data;
    })
    .catch(error=>{
        console.log(error.message);
        localStorage.setItem("loginMessage", JSON.stringify("Unauthorized error: Bad credentials"));
        throw new Error("Unauthorized error: Bad credentials");
      })
    ;
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getLoginMessage = () => {
  return JSON.parse(localStorage.getItem("loginMessage"));
}

const authService = {
  signup,
  signin,
  logout,
  getCurrentUser,
  getLoginMessage,
  server
};

export default authService;
