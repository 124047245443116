import React from 'react';
import { useState, useEffect } from "react";
import './App.css';
import { NavLink, Routes , Route, Link } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import Champs from './pages/ChampsPage.js'
import Drivers from './pages/DriversPage.js'
import Teams from './pages/TeamsPage.js'
import Login from './pages/Login.js'
import User from './pages/UserPage.js'
import AuthService from "./services/auth.service";
import { useNavigate } from "react-router-dom";

function App() {
  const [currentUser, setCurrentUser] = useState(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    console.log(user);

    if (user) {
      setCurrentUser(user);
    }
  }, []);

  const logOut = () => {
    console.log("logout");
    AuthService.logout();
    navigate("/login");
    window.location.reload();
  };

  return (
    
    <div className="container mt-3">
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/"} className="nav-link">
            Calendario temporada 2024/2025
            </Link>
          </li>
          <li className="nav-item"
            ><Link to='/teams' className="nav-link">
              Clasificación de escuderías
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/drivers' className="nav-link">
              Clasificación de pilotos
            </Link>
          </li>
        </div>

        {currentUser ? (
          <div className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                User Profile
              </Link>
            </li>
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut}>
                Logout
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container mt-3">
      <Routes>
        <Route exact path='/' element={ <Champs/> }/>
        <Route exact path='/drivers' element={<Drivers/> }/>
        <Route exact path='/teams' element={<Teams/> }/>
        <Route exact path='/user' element={<User/> }/>
        <Route exact path='/login' element={<Login/> }/>
      </Routes>
      </div>
    </div>
  );
}

export default App;
