import React, { Component } from 'react';
import '../App.css';
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import AuthService from "../services/auth.service";
import authHeader from "../services/auth.header";


//const server = "51.75.195.167";
const server = AuthService.server
const url = "https://" + server + "/api/f1/teamController/";
var currentUser = undefined

class Teams extends Component{

  state={
    data:[],
    modalInsertar: false,
    modalEliminar: false,
    form:{
      id: '',
      name: '',
      longName: '',
      initialBudget: '',
      currentBudget: '',
      drivers: '',
      points: '',
      manager: ''
    }
  }

  userLogged(){
    const user = AuthService.getCurrentUser();
    console.log("Current user in driverPage");
    console.log(user);

    if (user) {
      currentUser = user;
    }
    return user;
  }

  getTeams=()=>{
    axios.get(url + 'readAll')
  .then(response=>{
    this.setState({data: response.data.data});
    })
  }

  peticionPost=async()=>{
    delete this.state.form.id;
   await axios.post(url + 'create',this.state.form, { headers: authHeader() }).then(response=>{
      this.modalInsertar();
      this.getTeams();
    }).catch(error=>{
      console.log(error.message);
    })
  }
  
  peticionPut=()=>{
    axios.put(url+'update', this.state.form, { headers: authHeader() }).then(response=>{
      this.modalInsertar();
      this.getTeams();
    })
  }

  
  peticionDelete=()=>{
    axios.delete(url+'delete/'+this.state.form.id, { headers: authHeader() }).then(response=>{
      this.setState({modalEliminar: false});
      this.getTeams();
    })
  }
  
  modalInsertar=()=>{
    this.setState({modalInsertar: !this.state.modalInsertar});
  }

  modalEliminar=()=>{
    this.setState({modalEliminar: !this.state.modalEliminar});
  }
  
  selectTeam=(team)=>{
    this.setState({
      tipoModal: 'actualizar',
      form: {
        id: team.id,
        name: team.name,
        longName: team.longName,
        initialBudget: team.initialBudget,
        currentBudget: team.currentBudget,
        drivers: team.drivers,
        points: team.points,
        manager: team.manager
      }
    })
  }
  
  handleChange=async e=>{
  e.persist();
  await this.setState({
    form:{
      ...this.state.form,
      [e.target.name]: e.target.value
    }
  });
  console.log(this.state.form);
  }


componentDidMount(){
  this.getTeams();
}

render(){
  this.userLogged()
  const {form}=this.state;
  return (
    <div className="App">
    <br /><br />
    <h3>Clasificación de Escuderías temporada 2024/2025</h3>
    <br /><br />
    <table className="table ">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Presupuesto actual</th>
          <th>Puntos de constructor</th>
          <th>Manager</th>
          {currentUser ? (
            <th>
              <button className="btn btn-success" onClick={()=>{this.setState({form: null, tipoModal: 'insertar'}); this.modalInsertar()}}>Añadir Escudería</button>
            </th>
          ) : (<br/>)}
        </tr>
      </thead>
      <tbody>
        {this.state.data.map(team=>{
          return(
            <tr>
              <td>{team.name}</td>
              <td>{new Intl.NumberFormat("en-EN").format(team.currentBudget)}</td>
              <td>{team.points}</td>
              <td>{team.manager}</td>
              {currentUser ? (
              <td>
                    <button className="btn btn-primary" onClick={()=>{this.selectTeam(team); this.modalInsertar()}}><FontAwesomeIcon icon={faEdit}/></button>
                    {"   "}
                    <button className="btn btn-danger" onClick={()=>{this.selectTeam(team); this.setState({modalEliminar: true})}}><FontAwesomeIcon icon={faTrashAlt}/></button>
              </td>
              ) : (<br></br>)}
          </tr>
          )
        })}
      </tbody>
    </table>

    <Modal isOpen={this.state.modalInsertar}>
                <ModalHeader style={{display: 'block'}}>
                  {this.state.tipoModal=='insertar'?
                    <span class modal-header >Añadir Escudería</span>
                  :
                    <span class modal-header >Actualizar Escudería {form && form.name} {form && form.surname}</span>
                  } 
                  <span style={{float: 'right'}} onClick={()=>this.modalInsertar()}>x</span>
                </ModalHeader>
                <ModalBody>
                  <div className="form-group">
                    <label htmlFor="name">Nombre</label>
                    <input className="form-control" type="text" name="name" id="name" onChange={this.handleChange} value={form?form.name: ''}/>
                    <br />
                    <label htmlFor="longName">Nombre completo</label>
                    <input className="form-control" type="text" name="longName" id="longName" onChange={this.handleChange} value={form?form.longName: ''}/>
                    <br />
                    <label htmlFor="initialBudget">Presupuesto inicial</label>
                    <input className="form-control" type="number" name="initialBudget" id="initialBudget" onChange={this.handleChange} value={form?form.initialBudget:''}/>
                    <br />
                    <label htmlFor="currentBudget">Presupuesto actual</label>
                    <input className="form-control" type="number" name="currentBudget" id="currentBudget" onChange={this.handleChange} value={form?form.currentBudget:''}/>
                    <br />
                    <label htmlFor="points">Puntos de constructor</label>
                    <input className="form-control" type="number" name="points" id="points" onChange={this.handleChange} value={form?form.points: ''}/>
                    <br />
                    <label htmlFor="team">Manager</label>
                    <input className="form-control" type="text" name="manager" id="manager" onChange={this.handleChange} value={form?form.manager: ''}/>
                  </div>
                </ModalBody>

                <ModalFooter>
                  {this.state.tipoModal=='insertar'?
                    <button className="btn btn-success" onClick={()=>this.peticionPost()}>
                    Insertar
                  </button>: <button className="btn btn-primary" onClick={()=>this.peticionPut()}>
                    Actualizar
                  </button>
                  }
                  <button className="btn btn-danger" onClick={()=>this.setState({modalInsertar: false})}>Cancelar</button>
                </ModalFooter>
          </Modal>


          <Modal isOpen={this.state.modalEliminar}>
            <ModalHeader style={{display: 'block'}}>
              <span class modal-header >Eliminar Escudería {form && form.name} {form && form.surname}</span>
              <span style={{float: 'right'}} onClick={()=>this.modalEliminar()}>x</span>
            </ModalHeader>
            <ModalBody>
              <p class="centrarText">¿Estás seguro que deseas eliminar la escudería </p> <p class="negrita centrarText">{form && form.name} ?</p>
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-danger" onClick={()=>this.peticionDelete()}>Sí</button>
              <button className="btn btn-secundary" onClick={()=>this.setState({modalEliminar: false})}>No</button>
            </ModalFooter>
          </Modal>
  </div>
    );
}
}
export default Teams;
