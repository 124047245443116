import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isError, setError] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await AuthService.signin(username, password).then(
        () => {
          if(AuthService.getCurrentUser()!= null){
            setError(false);
            navigate("/");
            window.location.reload();
          }
        },
        (error) => {
          setError(true);
          setErrorMsg(error.message);
          console.log(error.message);
        }
      );
    } catch (err) {
      setError(true);
      setErrorMsg(err.message);
      console.log(err);
    }
  };

  return (
<div><br></br>
<br></br>
<br></br>

    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleLogin}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Usuario</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Introduzca su usuario"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Introduzca la contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Log in
            </button>
          </div>
          {isError ? (
            (<div class="alert alert-danger" role="alert">{errorMsg}</div>)
          ) : <p></p>
          }          
        </div>
      </form>
    </div>
    </div>
  );
};

export default Login;


