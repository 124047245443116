import React, { Component } from 'react';
import '../App.css';
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import AuthService from "../services/auth.service";
import authHeader from "../services/auth.header";
import Select from "react-select";

import DatePicker from "react-datepicker";
import { FaCalendarAlt } from 'react-icons/fa';

import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

setDefaultLocale('es');

const url = "https://"+ AuthService.server +"/api/f1/championshipController/";

const urlCountries = "https://"+ AuthService.server +"/api/f1/countryController/";

var currentUser = undefined;



class Champs extends Component{

  
  state={
    data:[],
    modalInsertar: false,
    modalEliminar: false,
    startDate: new Date(),
    form:{
      id: '',
      name: '',
      champDate: '',
      country: {
        id: null
       //,name: ''
      }
    }
  }

  
  userLogged(){
    const user = AuthService.getCurrentUser();
    //console.log("Current user in champPage");
    //console.log(user);
    if (user) {
      currentUser = user;
    }
    return user;
  }

  test(){
    console.log("test");
  }

  handleChangeDate= (event) => { 
    console.log(`Date selected:`, event);
    this.setState({  
      form:{
        ...this.state.form,
        champDate: event
        }
    }) ;
  }

  getChamps=()=>{
    axios.get(url + 'readAll')
  .then(response=>{
    this.setState({data: response.data.data});
    })
  }

  getCountries=()=>{
    axios.get(url + 'readAll')
  .then(response=>{
    console.log(response.data);
    return {data: response.data.data};
    //this.setState({data: response.data.data});
    })
  }

  formatDate=(timeStamp)=>{
    let options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    let date = new Date(timeStamp);
    date = date.toLocaleDateString("es-ES", options);
    return date.toString();
  }

  peticionPost=async()=>{
    delete this.state.form.id;
    console.log(this.state.form)
    console.log(this.state.form.champDate)
   await axios.post(url + 'create',this.state.form, { headers: authHeader() }).then(response=>{
      this.modalInsertar();
      this.getChamps();
    }).catch(error=>{
      console.log(error.message);
    })
  }
  
  peticionPut=()=>{
    console.log(this.state.form)
    axios.put(url+'update', this.state.form, { headers: authHeader() }).then(response=>{
      this.modalInsertar();
      this.getChamps();
    })
  }
  
  peticionDelete=()=>{
    axios.delete(url+'delete/'+this.state.form.id, { headers: authHeader() }).then(response=>{
      this.setState({modalEliminar: false});
      this.getChamps();
    })
  }
  
  modalInsertar=()=>{
    this.setState({modalInsertar: !this.state.modalInsertar});
  }

  modalEliminar=()=>{
    this.setState({modalEliminar: !this.state.modalEliminar});
  }
  
  selectChamp=(champ)=>{
    this.setState({
      tipoModal: 'actualizar',
      form: {
        id: champ.id,
        name: champ.name,
        champDate: champ.champDate,
        country: {
          id: champ.country.id,
          name: champ.country.name
        }
      }
    })
  }
  
  handleChange=async e=>{
  e.persist();
  await this.setState({
    form:{
      ...this.state.form,
      [e.target.name]: e.target.value
    }
  });
  console.log('handleChange' + this.state.form);
  }

  handleChangeCountry = (event) => {
    this.setState({
      form:{
      ...this.state.form,
        country: {id: event.value}
      }
    });
    console.log(`Option selected:`, event.value);
  };

  componentDidMount(){
    this.getChamps();
  }

  options = [
    { value: "0", label: "Jamaica"},
    { value: "1", label: "Japón"},
    { value: "2", label: "España"},
    { value: "3", label: "Escocia"},
    { value: "4", label: "Reino Unido"},
    { value: "5", label: "Francia"},
    { value: "6", label: "Brasil"},
    { value: "7", label: "Portugal"},
    { value: "8", label: "Italia"},
    { value: "9", label: "Canadá"},
    { value: "10", label: "Alemania "},
    { value: "11", label: "China"},
    { value: "12", label: "Barein"},
    { value: "13", label: "Mónaco"},
    { value: "14", label: "Azerbayán"},
    { value: "15", label: "Austria"},
    { value: "16", label: "Hungría"},
    { value: "17", label: "Bélgica"},
    { value: "18", label: "Holanda"},
    { value: "19", label: "Rusia"},
    { value: "20", label: "Estados Unidos"},
    { value: "21", label: "México"},
    { value: "22", label: "Australia"},
    { value: "23", label: "Arabia Saudí"},
    { value: "24", label: "Emiratos Árabes Unidos"},
  ];  

  handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

render(){
  this.userLogged()
  const {form}=this.state;
  return (
    <div className="App">
    <br /><br />
    <h3>Listado de Grandes Premios de la temporada 2024/2025</h3>
    <br /><br />
    <table className="table ">
      <thead>
        <tr>
          <th>Circuito</th>
          <th>Fecha</th>
          <th>País</th>
          {currentUser ? (            <th>
       <button className="btn btn-success" onClick={()=>{this.setState({form: null, tipoModal: 'insertar'}); this.modalInsertar()}}>Añadir Gran Premio</button>
      </th>
    ) : (<br/>)}
        </tr>
      </thead>
      <tbody>
        {this.state.data.map(champ=>{
          return(
            <tr>
              <td>{champ.name}</td>
              <td>{this.formatDate(champ.champDate)}</td>
              <td>{champ.country.name}</td>
              {currentUser ? (
              <td>
                    <button className="btn btn-primary" onClick={()=>{this.selectChamp(champ); this.modalInsertar()}}><FontAwesomeIcon icon={faEdit}/></button>
                    {"   "}
                    <button className="btn btn-danger" onClick={()=>{this.selectChamp(champ); this.setState({modalEliminar: true})}}><FontAwesomeIcon icon={faTrashAlt}/></button>
              </td>
              ) : (<br/>)}
          </tr>
          )
        })}
      </tbody>
    </table>

    

    <Modal isOpen={this.state.modalInsertar}>
                <ModalHeader style={{display: 'block'}}>
                {this.state.tipoModal==='insertar'?
                  <span class modal-header >Añadir Gran Premio</span>
                :
                  <span class modal-header >Actualizar Gran Premio</span>
                } 
                  <span style={{float: 'right'}} onClick={()=>this.modalInsertar()}>x</span>
                </ModalHeader>
                <ModalBody>
                  <div className="form-group">
                    <label htmlFor="name">Circuito</label>
                    <input className="form-control" type="text" name="name" id="name" onChange={this.handleChange} value={form?form.name: ''}/>
                    <br />
                    <label htmlFor="champDate">Fecha</label>
                    {this.state.tipoModal==='insertar'?
                    <div>
                      <DatePicker 
                        locale="es"
                        className="form-control"
                        showTimeInput 
                        selected={form?(form.champDate): ''} 
                        onChange={ this.handleChangeDate }  
                        name="startDate"  
                        dateFormat="MM/dd/yyyy HH:mm"  
                        timeInputLabel="Hora:"
                      />  
                      </div>
                    :
                    <div>
                    <DatePicker  
                      locale="es"
                      className="form-control"
                      showTimeInput
                      selected={form?(form.champDate): ''} 
                      onChange={ this.handleChangeDate }  
                      name="startDate"  
                      dateFormat="MM/dd/yyyy HH:mm"  
                      timeInputLabel="Hora:"
                     />  
                     </div>
                    }
                    <br />
                    <label htmlFor="country">País</label>
                    {this.state.tipoModal==='insertar'?
                      <div >
                        <div >
                          <Select options={this.options} onChange={this.handleChangeCountry} autoFocus={true} />
                        </div>
                     </div>
                      : 
                      <div>
                        <div>
                          <Select options={this.options} onChange={this.handleChangeCountry} autoFocus={true}  defaultValue={this.options[form?form.country.id: '']}/>
                        </div>
                     </div>
                    }
                  </div>
                </ModalBody>

                <ModalFooter>
                  {this.state.tipoModal==='insertar'?
                    <button className="btn btn-success" onClick={()=>this.peticionPost()}>
                    Insertar
                  </button>
                  : 
                  <button className="btn btn-primary" onClick={()=>this.peticionPut()}>
                    Actualizar
                  </button>
                  }
                  <button className="btn btn-danger" onClick={()=>this.setState({modalInsertar: false})}>Cancelar</button>
                </ModalFooter>
          </Modal>


          <Modal isOpen={this.state.modalEliminar}>
            <ModalHeader style={{display: 'block'}}>
                <span class modal-header >Eliminar Gran Premio</span>
                <span style={{float: 'right', cursor: 'pointer'}} onClick={()=>this.modalEliminar()}>x</span>
            </ModalHeader>
            <ModalBody>
              <p class="centrarText">¿Estás seguro que deseas eliminar el Gran Premio </p> <p class="negrita centrarText">{form && form.name} ?</p>
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-danger" onClick={()=>this.peticionDelete()}>Sí</button>
              <button className="btn btn-secundary" onClick={()=>this.setState({modalEliminar: false})}>No</button>
            </ModalFooter>
          </Modal>
  </div>
    );
}
}
export default Champs;
