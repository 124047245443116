import React, { Component } from 'react';
import '../App.css';
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select from "react-select";

import authHeader from "../services/auth.header";
import AuthService from "../services/auth.service";


//const server = "51.75.195.167";
const server = "api.eveonline.es"
const url = "https://" + server + "/api/f1/driverController/";
var currentUser = undefined

class Drivers extends Component{

  state={
    data:[],
    modalInsertar: false,
    modalEliminar: false,
    modalEdit: false,
    modalEditPoints: false,
    form:{
      id: '',
      name: '',
      surname: '',
      country:{id: null},
      salary: '',
      points: '',
      team:{id: null}
    }
  }

  userLogged(){
    const user = AuthService.getCurrentUser();
    console.log("Current user in driverPage");
    console.log(user);

    if (user) {
      currentUser = user;
    }
    return user;
  }

  getDrivers=()=>{
    axios.get(url + 'readAll')
  .then(response=>{
    this.setState({data: response.data.data});
    })
  }

  peticionPost=async()=>{
    delete this.state.form.id;
    console.log(this.state.form);
   await axios.post(url + 'create', this.state.form, { headers: authHeader() }).then(response=>{
      this.modalEdit();
      this.getDrivers();
    }).catch(error=>{
      console.log(error.message);
    })
  }
  
  peticionPutEdit=()=>{
    console.log(authHeader())
    axios.put(url+'update', this.state.form, { headers: authHeader() }).then(response=>{
      this.modalEdit();
      this.getDrivers();
    }).catch(error=>{
      console.log(error.message);
    })
  }

  peticionPut=()=>{
    axios.put(url + 'updatePoints/' + this.state.form.id + '/' + this.state.form.points, {},{ headers: authHeader()}).then(response=>{
      this.modalEditPoints();
      this.getDrivers();
    }).catch(error=>{
      console.log(error.message);
    })
  }
  
  peticionDelete=()=>{
    axios.delete(url + 'delete/' + this.state.form.id, { headers: authHeader() }).then(response=>{
      this.setState({modalEliminar: false});
      this.getDrivers();
    }).catch(error=>{
      console.log(error.message);
    })
  }
  
  modalInsertar=()=>{
    this.setState({modalInsertar: !this.state.modalInsertar});
  }

  modalEliminar=()=>{
    this.setState({modalEliminar: !this.state.modalEliminar});
  }

  modalEdit=()=>{
    this.setState({modalEdit: !this.state.modalEdit});
  }

  modalEditPoints=()=>{
    this.setState({modalEditPoints: !this.state.modalEditPoints});
  }
  
  selectDriver=(driver)=>{
    this.setState({
      tipoModal: 'actualizar',
      form: {
        id: driver.id,
        name: driver.name,
        surname: driver.surname,
        country: {id: driver.country.id
        , name: driver.country.name},
        salary: driver.salary,
        points: driver.points,
        team:{id: driver.team.id
        , name: driver.team.name}
      }
    })
  }
  
  handleChange=async e=>{
  e.persist();
  await this.setState({
    form:{
      ...this.state.form,
      [e.target.name]: e.target.value
    }
  });
  console.log(this.state.form);
  }

  handleChangeCountry = (event) => {
    this.setState({
      form:{
      ...this.state.form,
        country: {id: event.value}
      }
    });
  };

  handleChangeTeam = (event) => {
    this.setState({
      form:{
      ...this.state.form,
        team: {id: event.value}
      }
    });
  };

componentDidMount(){
  this.getDrivers();
}

options = [
  { value: "0", label: "Jamaica"},
  { value: "1", label: "Japón"},
  { value: "2", label: "España"},
  { value: "3", label: "Escocia"},
  { value: "4", label: "Reino Unido"},
  { value: "5", label: "Francia"},
  { value: "6", label: "Brasil"},
  { value: "7", label: "Portugal"},
  { value: "8", label: "Italia"},
  { value: "9", label: "Canadá"},
  { value: "10", label: "Alemania "},
  { value: "11", label: "China"},
  { value: "12", label: "Barein"},
  { value: "13", label: "Mónaco"},
  { value: "14", label: "Azerbayán"},
  { value: "15", label: "Austria"},
  { value: "16", label: "Hungría"},
  { value: "17", label: "Bélgica"},
  { value: "18", label: "Holanda"},
  { value: "19", label: "Rusia"},
  { value: "20", label: "Estados Unidos"},
  { value: "21", label: "México"},
  { value: "22", label: "Australia"},
  { value: "23", label: "Arabia Saudí"},
  { value: "24", label: "Emiratos Árabes Unidos"},
];  

optionsTeams = [
  { value: "0", label: ""},
  { value: "1", label: "Mc Laren"},
  { value: "2", label: "Aston Martin"},
  { value: "3", label: "Ferrari"},
  { value: "4", label: "Mercedes"},
  { value: "5", label: "Red Bull"},
  { value: "6", label: "Alpine"},
  { value: "7", label: "Alpha Tauri"},
  { value: "8", label: "Alfa Romeo"},
  { value: "9", label: "Williams"},
  { value: "10", label: "Haas "},
];  


render(){
  this.userLogged()
  const {form}=this.state;
  return (
    <div className="App">
    <br /><br />
    <h3>Puta Clasificación de Pilotos temporada 2024/2025</h3>
    <br /><br />
    <table className="table ">
      <thead>
        <tr>
          <th>Piloto</th>
          <th>Sueldo</th>
          <th>Puntos</th>
          <th>Escudería</th>
          {currentUser ? (
            <th>
              <button className="btn btn-success" onClick={()=>{this.setState({form: null, tipoModal: 'insertar'}); this.modalEdit()}}>Añadir Piloto</button>
            </th>
          ) : (<br/>)}
        </tr>
      </thead>
      <tbody>
        {this.state.data.map(driver=>{
          return(
            <tr>
              <td>{driver.name} {driver.surname}</td>
              <td>{new Intl.NumberFormat("en-EN").format(driver.salary)}</td>
              <td>{driver.points}</td>
              <td>{driver.team.name}</td>
              {currentUser ? (
              <td>
                    <button className="btn btn-primary" onClick={()=>{this.selectDriver(driver); this.modalEdit()}}><FontAwesomeIcon icon={faEdit}/></button>
                    {"   "}
                    <button className="btn btn-primary" onClick={()=>{this.selectDriver(driver); this.modalEditPoints()}}><FontAwesomeIcon icon={faFileEdit}/></button>
                    {"   "}
                    <button className="btn btn-danger" onClick={()=>{this.selectDriver(driver); this.setState({modalEliminar: true})}}><FontAwesomeIcon icon={faTrashAlt}/></button>
              </td>
              ) : (<br/>)}
          </tr>
          )
        })}
      </tbody>
    </table>

    <Modal isOpen={this.state.modalEdit}>
       <ModalHeader style={{display: 'block'}}>
        {this.state.tipoModal=='insertar'?
          <span class modal-header >Añadir Piloto</span>
        :
          <span class modal-header >Actualizar Piloto {form && form.name} {form && form.surname}</span>
        } 
        <span style={{float: 'right'}} onClick={()=>this.modalEdit()}>x</span>
      </ModalHeader>
      <ModalBody>
      <div className="form-group">
          <label htmlFor="name">Nombre</label>
          <input className="form-control" type="text" name="name" id="name" onChange={this.handleChange} value={form?form.name: ''}/>
          <br />
          <label htmlFor="surname">Apellido</label>
          <input className="form-control" type="text" name="surname" id="surname" onChange={this.handleChange} value={form?form.surname: ''}/>
          <br />
          <label htmlFor="salary">Salario</label>
          <input className="form-control" type="number" name="salary" id="salary" onChange={this.handleChange} value={form?form.salary:''}/>
          <br />
          <label htmlFor="points">Puntos</label>
          <input className="form-control" type="number" name="points" id="points" onChange={this.handleChange} value={form?form.points: ''}/>
          <br />
          <label htmlFor="team">Escudería</label>
          {this.state.tipoModal==='insertar'?
            <div className="container">
              <div >
                <Select options={this.optionsTeams} onChange={this.handleChangeTeam} autoFocus={true} />
              </div>
            </div>
            : 
            <div className="container">
              <div>
                <Select options={this.optionsTeams} onChange={this.handleChangeTeam} autoFocus={true}  defaultValue={this.optionsTeams[form?form.team.id: '']}/>
              </div>
            </div>
          }
          <br />
          <label htmlFor="country">País</label>
          {this.state.tipoModal==='insertar'?
            <div className="container">
              <div >
                <Select options={this.options} onChange={this.handleChangeCountry} autoFocus={true} />
              </div>
            </div>
            : 
            <div className="container">
              <div>
                <Select options={this.options} onChange={this.handleChangeCountry} autoFocus={true}  defaultValue={this.options[form?form.country.id: '']}/>
              </div>
            </div>
          }
        </div>
      </ModalBody>

      <ModalFooter>
        {this.state.tipoModal=='insertar'?
          <button className="btn btn-success" onClick={()=>this.peticionPost()}>
          Insertar
        </button>: <button className="btn btn-primary" onClick={()=>this.peticionPutEdit()}>
          Actualizar
        </button>}
        <button className="btn btn-danger" onClick={()=>this.modalEdit()}>Cancelar</button>
      </ModalFooter>
    </Modal>

    <Modal isOpen={this.state.modalEditPoints}>
      <ModalHeader style={{display: 'block'}}>
        <span class modal-header >Actualizar puntos del Piloto {form && form.name} {form && form.surname}</span>
        <span style={{float: 'right'}} onClick={()=>this.modalEditPoints()}>x</span>
      </ModalHeader>
      <ModalBody>
        <div className="form-group">
          <label htmlFor="points">Puntos</label>
          <input className="form-control" type="number" name="points" id="points" onChange={this.handleChange} value={form?form.points: ''}/>
        </div>
      </ModalBody>

      <ModalFooter>
        {this.state.tipoModal=='insertar'?
          <button className="btn btn-success" onClick={()=>this.peticionPost()}>
          Insertar
        </button>: <button className="btn btn-primary" onClick={()=>this.peticionPut()}>
          Actualizar
        </button>}
        <button className="btn btn-danger" onClick={()=>this.modalEditPoints()}>Cancelar</button>
      </ModalFooter>
    </Modal>

    <Modal isOpen={this.state.modalEliminar}>
      <ModalHeader style={{display: 'block'}}>
        <span class modal-header >Eliminar Piloto {form && form.name} {form && form.surname}</span>
        <span style={{float: 'right'}} onClick={()=>this.modalEliminar()}>x</span>
      </ModalHeader>
      <ModalBody>
        <p class="centrarText">¿Estás seguro que deseas eliminar el piloto </p> <p class="negrita centrarText">{form && form.name} {form && form.surname} ?</p>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={()=>this.peticionDelete()}>Sí</button>
        <button className="btn btn-secundary" onClick={()=>this.setState({modalEliminar: false})}>No</button>
      </ModalFooter>
    </Modal>
  </div>
    );
}
}
export default Drivers;
