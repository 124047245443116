import React, { Component } from 'react';
import '../App.css';
import AuthService from "../services/auth.service";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

var currentUser = undefined

class Champs extends Component{

    userLogged(){
        const user = AuthService.getCurrentUser();
        //console.log("Current user in champPage");
        console.log(user);
        if (user) {
            currentUser = user;
        }
        return user;
    }

      render(){
        var a = this.userLogged()
        //const {form}=this.state;
        return (
            <div className="App">
            <br /><br />
            <h3>Información del usuario activo</h3>
            <br /><br />
            <div className="App">
                <div>User: {a.username}</div>
                <div>Email: {a.email}</div>
                <div>Roles: {a.roles}</div>
                <div>Token: {a.accessToken}</div>
            </div>
            </div>
          );
        }
}
export default Champs;
